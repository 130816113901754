<template>
  <BackgroundWaves color="#292827" />
  <v-container class="wrapper-container" fluid>
    <v-container class="top-bar">
      <NavigationButtonLeftComponent @back="back"></NavigationButtonLeftComponent>
      <v-spacer></v-spacer>
      <v-img class="home-img-logo" :src="LoginLogo"></v-img>
    </v-container>
  </v-container>

  <v-container class="topic-container" fluid>
    <div class="topic-text">
      <h1>Themenbereiche</h1>
    </div>

    <v-row id="collection">
      <v-col v-for="(topic, index) in topics" :key="index" class="topic-cols">
        <v-card :class="topic.color" class="topic-cards">
          <v-img class="img-cards" :src="topic.picture_base64" alt="Bild"></v-img>
          <v-card-subtitle class="mt-3 count-questions-home">{{ getFilteredQuestionsCount(questions, topic.id, status) }} Fragen</v-card-subtitle>
          <v-card-title class="custom-title">{{ topic.title }}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="() => startQuiz(topic)" block text="Spielen" class="white-button"></v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-container class="scroll-buttons">
      <v-btn class="mr-3  button-normal" @click="scrollLeft">
        <v-icon class="material-symbols-rounded">west</v-icon>
      </v-btn>
      <v-btn class="button-normal" @click="scrollRight">
        <v-icon class="material-symbols-rounded">east</v-icon>
      </v-btn>
    </v-container>
  </v-container>
</template>

<script setup>
import router from "@/router";
import BackgroundWaves from "@/components/BackgroundWavesComponent.vue";
import { getAllTopics, getAllQuestions } from "@/services/APIService";
import { onMounted, ref } from "vue";
import LoginLogo from "@/assets/LoginLogo.png";
import { useTopicStore } from "@/stores/TopicStore";
import { useQuestionStore } from "@/stores/QuestionStore";
import NavigationButtonLeftComponent from "@/components/NavigationButtonLeftComponent.vue";

let topics = ref();
let questions = ref([]);
const topicStore = useTopicStore();
const questionStore = useQuestionStore();
let color = [
  'blue',
  'yellow',
  'green',
  'red',
  'pink',
];

const scrollLeft = () => {
  const collection = document.querySelector('#collection');
  collection.scrollLeft -= 261;
};

const scrollRight = () => {
  const collection = document.querySelector('#collection');
  collection.scrollLeft += 261;
};

const back = async () => {
  await router.back();
};

const status = "normal";

const getFilteredQuestionsCount = (questions, topicId, status) => {
  return (questions.filter(question =>
    question.topic_id === topicId && question.status === status
  )).length;
}

onMounted(async () => {
  topicStore.clearTopic();
  topics.value = await getAllTopics();
  topics = topics.value.map((topic, index) => {
    return {
      ...topic,
      color: color[index % color.length],

    };
  })
  questions.value = await getAllQuestions();
  questionStore.setQuestion(questions.value)
});

const startQuiz = (topic) => {
  topicStore.setTopic(topic);

  router.push({ name: 'preview' });
};
</script>

<style>
body::-webkit-scrollbar {
  display: none;
}

.example::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.custom-title {
  white-space: wrap;
}
</style>