import 'vuetify/dist/vuetify.min.css';
import { createVuetify } from 'vuetify';

// Importiere die Material Design Icons
import { md } from 'vuetify/iconsets/md';


// Konfiguriere Vuetify mit den gewünschten Icons
export default createVuetify({
    icons: {
        defaultSet: 'md', // Setze das Standard-Iconset auf mdi (Material Design Icons)
        sets: {
            md, // Füge das mdi-Iconset hinzu
        },
    },
});
