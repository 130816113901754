import { defineStore } from 'pinia';

export const useQuestionStore = defineStore('QuestionStore', {
  state: () => ({
    questions: null,
    selectedQuestions: null
  }),
  actions: {
    setQuestion(questions) {
      this.questions = questions;
      this.selectedQuestions = this.questions
    },
    clearQuestion() {
      this.questions = null;
    }
  },
  persist: true,
});
