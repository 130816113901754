<template>
  <BackgroundWaves  color="#292827" />
  <v-container class="top-bar">
    <NavigationButtonLeftComponent @back="back"></NavigationButtonLeftComponent>
    <v-spacer></v-spacer>
  </v-container>
      <v-container :class="topicStore.topic.color" class="end-container">
        <h1>Danke für das Mitmachen</h1>
        <h3>Sie haben hiermit das Quiz beendet</h3>
        <v-container class="container-endview">
          <v-btn class="text-none" @click="startResult" :style="{backgroundColor: topicStore.topic.BtnColor }">Abschliessen</v-btn>
        </v-container>
      </v-container>
</template>

<script setup>
import router from "@/router";
import BackgroundWaves from "@/components/BackgroundWavesComponent.vue";
import {useTopicStore} from "@/stores/TopicStore";
import NavigationButtonLeftComponent from "@/components/NavigationButtonLeftComponent.vue";

const topicStore = useTopicStore();


function startResult() {
  router.push({ name: 'home' });

}
const back = async () => {
  await router.back();
};
</script>

