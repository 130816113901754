import { defineStore } from 'pinia';

export const useTopicStore = defineStore('TopicStore', {
  state: () => ({
    topic: null,
    topics: null
  }),
  actions: {
    setTopic(topic) {
      this.topic = topic;
    },
    setTopics(topics) {
      this.topics = topics;
    },
    clearTopic() {
      this.topic = null;
    },
  },
  persist: true,

});
