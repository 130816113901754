<template>
  <BackgroundWaves color="#292827" />

  <v-container class="wrapper-container" fluid :class="bgColor">
    <NavigationButtonLeftComponent @back="back"></NavigationButtonLeftComponent>
    <!-- Progress bar container -->
    <v-container class="progress-container">
      <div class="progress-bar-full">
        <div class="progress-bar-full-number">{{ questions.length }}</div>
      </div>
      <div class="progress-bar-now">
        <div class="progress-bar-now-number">{{ step + 1 }}</div>
      </div>
    </v-container>
    <v-spacer></v-spacer>
  </v-container>
  <v-container class="quiz-container">
    <v-window v-model="step">
      <v-window-item v-for="(question, index) in questions" :key="index" :value="index" :transition="false">
        <v-container v-if="showEnd">
          <v-container class="centered-container">
            <v-container class="main-container display-container" :class="topic.color">
              <v-container class="video-container" :class="topic.color">
                <v-container class="question-container">
                  <h1 class="title">{{ question.question }}</h1>
                </v-container>
                <div class="video-wrapper">
                  <video v-if="question.video" class="video-height" controls>
                    <source :src="`https://gum002.dev.cubera.ch/video/${question.video_stment}`" type="video/mp4">
                    Your browser does not support HTML video.
                  </video>
                  <p v-else>Loading video...</p>
                </div>
              </v-container>
            </v-container>
            <v-btn @click="startResult(question.answer)" class="ready-button">Ready?</v-btn>
          </v-container>
        </v-container>
        <v-container v-else>
          <v-container class="question-container">
            <h1>{{ question.question }}</h1>
          </v-container>
          <v-container v-if="showResult">
            <v-row class="display-container">
              <v-col :class="truthColumn">
                <h3 class="column-text">Fakt</h3>
              </v-col>
              <v-col :class="bothColumn">
                <h3 class="column-text">Mein Vibe</h3>
              </v-col>
              <v-col :class="nonsenseColumn">
                <h3 class="column-text">Blödsinn</h3>
              </v-col>
            </v-row>
            <v-row>
              <v-col></v-col>
              <v-col>
                <v-container class="timer-container" v-if="showTimer">
                  <p class="timer-number">{{ min }}:{{ sec }}</p>
                </v-container>
                <v-container class="button-quiz-container" v-else>
                  <v-btn @click="showViedo">Klär mich auf</v-btn>
                </v-container>
              </v-col>
              <v-col></v-col>
            </v-row>
          </v-container>
          <v-container class="video-container" v-else>
            <div class="video-wrapper">
              <video v-if="question.video" width="max-width" class="video-height" controls>
                <source :src="`https://gum002.dev.cubera.ch/video/${question.video}`" type="video/mp4">
                Your browser does not support HTML video.
              </video>
              <p v-else>Loading video...</p>
            </div>
            <v-container class="bottom-bar">
              <v-btn @click="next">
                Weiter
              </v-btn>
            </v-container>
          </v-container>
        </v-container>
      </v-window-item>
    </v-window>
  </v-container>
</template>

<script setup>
import { ref } from "vue";
import router from "@/router";
import BackgroundWaves from "@/components/BackgroundWavesComponent.vue";
import { useQuestionStore } from "@/stores/QuestionStore";
import { useTopicStore } from "@/stores/TopicStore";
import NavigationButtonLeftComponent from "@/components/NavigationButtonLeftComponent.vue";

let showEnd = ref(true);
let beforeResult = ref(false)
let showResult = ref(true);
let millisec = ref();
let sec = ref();
let min = ref();
let step = ref(0);
let showTimer = ref(true)
let truthColumn = ref("truth-column");
let bothColumn = ref("both-column");
let nonsenseColumn = ref("nonsense-column")

const QuestionStore = useQuestionStore();
const questions = QuestionStore.selectedQuestions;


const TopicStore = useTopicStore();
const topic = TopicStore.topic;
let progress = ref(((step.value + 1) / questions.length) * 100);
console.log(questions.length)
console.log(progress.value)

function next() {
  const tempStep = step.value + 1;

  if (questions.length > tempStep) {
    step.value++;
    progress.value = ((step.value + 1) / questions.length) * 100; // Update progress
    showEnd.value = true;
    showResult.value = true;
    truthColumn.value = "truth-column";
    bothColumn.value = "both-column"
    nonsenseColumn.value = "nonsense-column"
    showTimer.value = true
  } else {
    router.push({ name: 'end' });
  }
}

function startResult(rightAnswer) {
  showEnd.value = false;
  beforeResult.value = true;

  const endTime = new Date().getTime() + 15 * 1000;

  function getRemainingTime(deadline) {
    const currentTime = new Date().getTime();
    return deadline - currentTime;
  }

  function pad(value) {
    return ("0" + Math.floor(value)).slice(-2);
  }

  function showTime() {
    const remainingTime = getRemainingTime(endTime);

    min.value = pad((remainingTime / (60 * 1000)) % 60);
    sec.value = pad((remainingTime / 1000) % 60);
    millisec.value = pad(remainingTime % 60);

    if (remainingTime >= 0) {
      requestAnimationFrame(showTime);
    } else {
      showTimer.value = false;

      const columnClasses = {
        "Fakt": ["true-column", "false-column", "false-column"],
        "Mein Vibe": ["false-column", "true-column", "false-column"],
        "Blödsinn": ["false-column", "false-column", "true-column"]
      };

      const [truthClass, bothClass, nonsenseClass] = columnClasses[rightAnswer] || ["false-column", "false-column", "false-column"];

      truthColumn.value += ` ${truthClass}`;
      bothColumn.value += ` ${bothClass}`;
      nonsenseColumn.value += ` ${nonsenseClass}`;
    }
  }
  requestAnimationFrame(showTime);
}

function showViedo() {
  showResult.value = false;

}

const back = async () => {
  await router.back();
};
</script>

<style scoped>
.progress-bar-now {
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: v-bind(progress + '%');
  border-radius: 20px;
  background-color: #FF5378;
  transition: width 1s ease;
}
</style>
