<template>
    <BackgroundWaves color="#292827" />

    <div class="centered-container">
        <v-img class="login-img-logo" :src="LoginLogo"></v-img>
        <div class="welcome-text">
            <h1>Herzlich willkommen <br> zum GummiLove-Quiz!</h1>
        </div>

        <div>
            <v-card
                class="mx-auto pa-10 pt-8 bg-white"
                elevation="8"
                width="448"
                rounded="lg"
                variant="outlined"
            >
                <h2 class="text-center mb-7">Anmelden</h2>

                <v-form ref="LoginForm">
                    <v-text-field
                        v-model="email"
                        density="compact"
                        placeholder="E-Mail or Benutzername"
                        variant="outlined"
                        :rules="[ /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email) || 'E-mail must be valid']"
                    ></v-text-field>

                    <v-text-field
                        v-model="password"
                        :append-inner-icon="visible ? 'visibility_off' : 'visibility'"
                        :type="visible ? 'text' : 'password'"
                        density="compact"
                        placeholder="Passwort"
                        variant="outlined"
                        @click:append-inner="visible = !visible"
                        :rules="[
                            password => !!password || 'Falsch'
                        ]"
                    ></v-text-field>

                    <div class="form-fields-container">
                        <div class="half-width">
                            <v-text-field
                                v-model="school"
                                density="compact"
                                placeholder="Schule"
                                variant="outlined"
                                class="half-width"
                            ></v-text-field>
                        </div>
                        <div class="half-width">
                            <v-text-field
                                v-model="klasse"
                                density="compact"
                                placeholder="Klasse"
                                variant="outlined"
                                class="half-width"
                            ></v-text-field>
                        </div>
                    </div>

                    <v-row class="d-flex justify-space-between">
                        <v-col>
                            <v-checkbox true-icon="check_box" false-icon="check_box_outline_blank" label="Angemeldet bleiben" class="stay-logged-in"></v-checkbox>
                        </v-col>
                        <v-col>
                            <a class="password-forgot" href="#" rel="noopener noreferrer" target="_blank">
                                Passwort vergessen?
                            </a>
                        </v-col>
                    </v-row>

                    <v-btn
                        size="large"
                        variant="outlined"
                        rounded
                        block
                        :disabled="!isValid"
                        @click="LoginBtn"
                    >
                        Log in
                    </v-btn>
                </v-form>
            </v-card>
        </div>
    </div>
</template>

<script setup>
import LoginLogo from '/src/assets/LoginLogo.png';
import { ref, computed } from 'vue';
import BackgroundWaves from '@/components/BackgroundWavesComponent.vue';
import { login } from "@/services/APIService";

let visible = ref(false);
let email = ref('');
let password = ref('');
let school = ref('');
let klasse = ref('');
let LoginForm = ref(null);

function LoginBtn() {
    login(email.value, password.value, school.value, klasse.value);
}

const isValid = computed(() => {
    return email.value.trim() !== '' && password.value.trim() !== '' && school.value.trim() !== '' && klasse.value.trim() !== '';
});
</script>