import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PreviewView from "@/views/PreviewView.vue";
import StartView from "@/views/StartView.vue";
import EndView from "@/views/EndView.vue";
import QuizView from "@/views/QuizView.vue";
import LoginView from "@/views/LoginView.vue";

const routes = [
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/preview',
    name: 'preview',
    component: PreviewView
  },
  {
    path: '/start',
    name: 'start',
    component: StartView
  },
  {
    path: '/end',
    name: 'end',
    component: EndView
  },
  {
    path: '/quiz',
    name: 'quiz',
    component: QuizView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
