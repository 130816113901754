<template>
   <v-container class="wrapper-container" fluid :class="bgColor">
     <v-container class="top-bar" >
       <NavigationButtonLeftComponent @back="back"></NavigationButtonLeftComponent>
       <v-spacer></v-spacer>
     </v-container>
   </v-container>
   <v-container  class="full-height" :class="bgColor">
     <v-container  v-if="start">
       <v-container class="start-container">
         <h2 class="h2-start-view">
           Gleich geht’s los!
         </h2>
         <v-container class="border-title">
           <h1 class="h1-start-view">
             Macht euch bereit
           </h1>
         </v-container>
       </v-container>
       <v-container class="bottom-bar">
         <v-btn @click="startCountdown" class="count-down-btn">Countdown starten  </v-btn>
         <p class="small-paragraph">Das Spiel startet nach 5 Sekunden.</p>
       </v-container>
     </v-container>
     <v-container  class="start-container" v-if="!start">
       <h1 class="big-numbers" :style="{color:  nrColor }">{{timerCount}}</h1>
     </v-container>
   </v-container>
</template>
<script setup>
import {ref} from "vue";
import router from "@/router";
import {onBeforeRouteLeave} from "vue-router";
import NavigationButtonLeftComponent from "@/components/NavigationButtonLeftComponent.vue";
import {useTopicStore} from "@/stores/TopicStore";

const topicStore = useTopicStore();
let bgColor = ref(topicStore.topic.color)
let start = ref(true)

const timerCount = ref(5);
let timer = null;
let color = [
  'pink',
  'green',
  'yellow',
  'blue',
  'red',
];

let colorIndex = 0;


function startCountdown() {
  bgColor.value = color[colorIndex];
  start.value = false;
  timer = setInterval(() => {
    if (timerCount.value > 1) {
      colorIndex = colorIndex + 1;
      bgColor.value = color[colorIndex];
      timerCount.value--;

    } else {
      clearInterval(timer);
      router.push({ name: 'quiz' });
    }
  }, 1000);
}
const back = async () => {
  await router.back();
};

onBeforeRouteLeave((to, from, next) => {
  clearInterval(timer);
  timerCount.value = 5;
  start.value = true;
  bgColor.value = "#33C7F4";
  next();
});
</script>

<style>
.count-down-btn {
  border-radius: 35px;
  border: 1px solid #292827;
  background: #FFED65;
  box-shadow: 1px 2px 0px 0px #000;
}
  </style>